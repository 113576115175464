<template>
  <!-- About Me Subpage -->
  <section data-id="about-me" class="animated-section">
    <div class="section-content">
      <div class="page-title">
        <h2>About <span>Me</span></h2>
      </div>

      <!-- Personal Information -->
      <div class="row">
        <div class="col-xs-12 col-sm-7">
          <p>
            Unleashing innovation, encouraging growth, and driving excellence
            are the cornerstones of my professional ethos. With an open mind to
            tackle challenges, I apply my comprehensive experience to every
            project I undertake, striving for optimal outcomes. I am direct and
            candid in my opinions, always prioritizing honesty over tact, and I
            avoid engaging in evasive or roundabout tactics.
          </p>
        </div>

        <div class="col-xs-12 col-sm-5">
          <div class="info-list">
            <ul>
              <li>
                <span class="title">Age</span>
                <span class="value">25+</span>
              </li>

              <li>
                <span class="title">Residence</span>
                <span class="value">USA</span>
              </li>

              <li>
                <span class="title">State</span>
                <span class="value">Alaska</span>
              </li>

              <li>
                <span class="title">eMail</span>
                <span class="value">contact@fonserve.net</span>
              </li>

              <li>
                <span class="title">Phone</span>
                <span class="value">+1 (860) 375-0697</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!-- End of Personal Information -->

      <div class="white-space-50"></div>

      <!-- Services -->
      <div class="row">
        <div class="col-xs-12 col-sm-12">
          <div class="block-title">
            <h3>What <span>I Do</span></h3>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-xs-12 col-sm-6">
          <div class="col-inner">
            <div class="info-list-w-icon">
              <div class="info-block-w-icon">
                <div class="ci-icon">
                  <i class="lnr lnr-store"></i>
                </div>
                <div class="ci-text">
                  <h4>Business Support</h4>
                  <p>
                    Ever since I was a kid, I always loved helping out
                    friends/family with their business's IT needs. Today, I make
                    it my job to help those who need it. I can provide your
                    business with a variety of services, including but not
                    limited to: Network Support, Server Support, and Desktop
                    Support.
                  </p>
                </div>
              </div>
              <div class="info-block-w-icon">
                <div class="ci-icon">
                  <i class="lnr lnr-laptop-phone"></i>
                </div>
                <div class="ci-text">
                  <h4>Web Design</h4>
                  <p>
                    I can help you design a website that will help you stand out
                    from the competition. I have experience with a variety of
                    web design tools, including but not limited to: HTML, CSS,
                    and JavaScript. I can also help you with SEO and social
                    media marketing to help you get your website noticed.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xs-12 col-sm-6">
          <div class="col-inner">
            <div class="info-list-w-icon">
              <div class="info-block-w-icon">
                <div class="ci-icon">
                  <i class="lnr lnr-pencil"></i>
                </div>
                <div class="ci-text">
                  <h4>Consulting</h4>
                  <p>
                    I can help provide tailored solutions and proactive support
                    to ensure robust security and optimal system performance.
                    Consulting services focused on network engineering,
                    cybersecurity, cloud technologies, and DevOps to drive
                    innovation and efficiency for businesses of all sizes.
                  </p>
                </div>
              </div>
              <div class="info-block-w-icon">
                <div class="ci-icon">
                  <i class="lnr lnr-lock"></i>
                </div>
                <div class="ci-text">
                  <h4>Security</h4>
                  <p>
                    With over 10 years of experience in the IT industry, I have
                    the skills and knowledge to help you secure your business
                    from cyber threats. I am certified in a variety of security
                    technologies, including but not limited to: Cisco, Palo
                    Alto, and Fortinet. I can help you secure your network,
                    servers, and endpoints to keep your business safe.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- End of Services -->

      <div class="white-space-30"></div>

      <!-- Testimonials -->
      <div class="row">
        <div class="col-xs-12 col-sm-12">
          <div class="block-title">
            <h3>Testimonials</h3>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-xs-12 col-sm-12">
          <div class="testimonials owl-carousel">
            <!-- Testimonial 1 -->
            <div class="testimonial">
              <div class="img">
                <img
                  src="img/testimonials/testimonial-1.jpg"
                  alt="Alex Smith"
                />
              </div>
              <div class="text">
                <p>
                  I highly recommend Bryce as an outstanding software engineer.
                  He is exceptional proficiency in software architecture and
                  design, consistently delivers high-quality code, adheres to
                  best practices and ensuring the robustness of the codebase.
                  Bryce excels in problem-solving, approaching challenges with a
                  strategic mindset and providing innovative solutions. He also
                  has an amazing personality that is guaranteed to make you
                  smile every single day.
                </p>
              </div>

              <div class="author-info">
                <h4 class="author">Kevin Kretz</h4>
                <h5 class="company">Booz Allen Hamilton</h5>
                <div class="icon">
                  <i class="fas fa-quote-right"></i>
                </div>
              </div>
            </div>
            <!-- End of Testimonial 1 -->

            <!-- Testimonial 2 -->
            <div class="testimonial">
              <div class="img">
                <img
                  src="img/testimonials/testimonial-2.jpg"
                  alt="Alex Smith"
                />
              </div>
              <div class="text">
                <p>
                  It was a privilege to have Bryce as a teammate! He
                  consistently showcased outstanding skills and qualities that
                  made him a valuable asset to the entire Capabilities Division.
                  I have had the pleasure of working closely with Bryce and have
                  continually been impressed by his "go-getter" attitude and
                  self-starter mentality. He has a passion for problem solving,
                  and often goes above and beyond to achieve excellence in his
                  work.
                </p>
              </div>

              <div class="author-info">
                <h4 class="author">Mariah Mommsen</h4>
                <h5 class="company">USAF Ret.</h5>
                <div class="icon">
                  <i class="fas fa-quote-right"></i>
                </div>
              </div>
            </div>
            <!-- End of Testimonial 2 -->

            <!-- Testimonial 3 -->
            <div class="testimonial">
              <div class="img">
                <img
                  src="img/testimonials/testimonial-3.jpg"
                  alt="Alex Smith"
                />
              </div>
              <div class="text">
                <p>
                  As the Intelligence Commander in Alaska, I commend SSgt
                  Fountain for his exceptional contributions to our intelligence
                  community. As the lead network engineer, he has provided vital
                  network diagrams, system reports, and proposals, significantly
                  enhancing our infrastructure. His innovative automation of
                  remote SATCOM site infrastructure has eased operational
                  burdens, improving efficiency and morale. Additionally, his
                  support for our DevOps environment has ensured seamless
                  application integration and functionality. SSgt Fountain’s
                  dedication and ingenuity have greatly advanced our mission
                  readiness. It is with great pride that I recognize his
                  outstanding service.
                </p>
              </div>

              <div class="author-info">
                <h4 class="author">Daniel Knappmiller</h4>
                <h5 class="company">Intelligence Commander</h5>
                <div class="icon">
                  <i class="fas fa-quote-right"></i>
                </div>
              </div>
            </div>
            <!-- End of Testimonial 3 -->
          </div>
        </div>
      </div>
      <!-- End of Testimonials -->

      <div class="white-space-50"></div>

      <!-- Clients -->
      <div class="row">
        <div class="col-xs-12 col-sm-12">
          <div class="block-title">
            <h3>Organizations</h3>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-xs-12 col-sm-12">
          <div class="clients owl-carousel">
            <div class="client-block">
              <a href="#" target="_blank" title="Logo">
                <img src="img/clients/client-1.png" alt="Logo" />
              </a>
            </div>

            <div class="client-block">
              <a href="#" target="_blank" title="Logo">
                <img src="img/clients/client-2.png" alt="Logo" />
              </a>
            </div>

            <div class="client-block">
              <a href="#" target="_blank" title="Logo">
                <img src="img/clients/client-3.png" alt="Logo" />
              </a>
            </div>

            <div class="client-block">
              <a href="#" target="_blank" title="Logo">
                <img src="img/clients/client-4.png" alt="Logo" />
              </a>
            </div>

            <div class="client-block">
              <a href="#" target="_blank" title="Logo">
                <img src="img/clients/client-5.png" alt="Logo" />
              </a>
            </div>

            <div class="client-block">
              <a href="#" target="_blank" title="Logo">
                <img src="img/clients/client-6.png" alt="Logo" />
              </a>
            </div>

            <div class="client-block">
              <a href="#" target="_blank" title="Logo">
                <img src="img/clients/client-7.png" alt="Logo" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <!-- End of Clients -->

      <div class="white-space-50"></div>

      <!-- Pricing -->
      <div class="row">
        <div class="col-xs-12 col-sm-12">
          <div class="block-title">
            <h3>Salary and Target</h3>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-xs-12 col-sm-12">
          <div class="fw-pricing clearfix row">
            <div class="fw-package-wrap col-md-6">
              <div class="fw-package">
                <div class="fw-heading-row">
                  <span>Current Salary </span>
                </div>

                <div class="fw-pricing-row">
                  <span>$64,000</span>
                  <small>per year</small>
                </div>

                <!-- <div class="fw-button-row">
                  <a href="#" target="_self" class="btn btn-secondary"
                    >Free Trial</a
                  >
                </div>

                <div class="fw-default-row">Lorem ipsum dolor</div>
                <div class="fw-default-row">Pellentesque scelerisque</div>
                <div class="fw-default-row">Morbi eu sagittis</div> -->
              </div>
            </div>

            <div class="fw-package-wrap col-md-6 highlight-col">
              <div class="fw-package">
                <div class="fw-heading-row">
                  <span>Target Salary</span>
                </div>

                <div class="fw-pricing-row">
                  <span>$158,000</span>
                  <small>per year</small>
                </div>

                <!-- <div class="fw-button-row">
                  <a href="#" target="_self" class="btn btn-primary"
                    >Free Trial</a
                  >
                </div>

                <div class="fw-default-row">Lorem ipsum dolor</div>
                <div class="fw-default-row">Pellentesque scelerisque</div>
                <div class="fw-default-row">Morbi eu sagittis</div>
                <div class="fw-default-row">Donec non diam</div>
                <div class="fw-default-row"></div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- End of Pricing -->

      <div class="white-space-50"></div>

      <!-- Fun Facts -->
      <div class="row">
        <div class="col-xs-12 col-sm-12">
          <div class="block-title">
            <h3>Fun <span>Facts</span></h3>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-xs-12 col-sm-4">
          <div class="fun-fact gray-default">
            <i class="lnr lnr-heart"></i>
            <h4>Years of Experience</h4>
            <span class="fun-fact-block-value">{{ yearsOfExperiance }}</span>
            <span class="fun-fact-block-text"></span>
          </div>
        </div>

        <div class="col-xs-12 col-sm-4">
          <div class="fun-fact gray-default">
            <i class="lnr lnr-clock"></i>
            <h4>Years of Service</h4>
            <span class="fun-fact-block-value">{{ yearsOfService }}</span>
            <span class="fun-fact-block-text"></span>
          </div>
        </div>

        <div class="col-xs-12 col-sm-4">
          <div class="fun-fact gray-default">
            <i class="lnr lnr-star"></i>
            <h4>Awards Won</h4>
            <span class="fun-fact-block-value">15</span>
            <span class="fun-fact-block-text"></span>
          </div>
        </div>
      </div>
      <!-- End of Fun Facts -->
    </div>
  </section>
  <!-- End of About Me Subpage -->
</template>

<script>
export default {
  name: "AboutSec",
  data() {
    return {
      yearsOfService: 0,
      startDate: new Date("2014-01-01"),
      yearsOfExperiance: 0,
      serviceDate: new Date("2018-01-23")
    };
  },
  created() {
    this.computeYearsOfService();
    this.computeYearsOfExperiance();
  },
  methods: {
    computeYearsOfExperiance() {
      const currentDate = new Date();
      const startYear = this.startDate.getFullYear();
      const currentYear = currentDate.getFullYear();
      this.yearsOfExperiance = currentYear - startYear;
    },
    computeYearsOfService() {
      const currentDate = new Date();
      const startYear = this.serviceDate.getFullYear();
      const currentYear = currentDate.getFullYear();
      this.yearsOfService = currentYear - startYear;
    }
  }
};
</script>

<style></style>
